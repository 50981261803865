<template>
  <div class="page">
    <div class="top_box">
      <div class="name_box">
        <div class="box_l"><span>*</span>超话标题:</div>
        <div class="box_r">
          <input
            v-model="form.topicTitle"
            type="text"
            placeholder="请输入超话标题"
          />
        </div>
      </div>
      <div class="status_box">
        <div class="box_l"><span></span>副标题:</div>
        <div class="box_r">
          <input
            v-model="form.informationCopywriting"
            type="text"
            placeholder="请输入副标题"
          />
        </div>
      </div>
      <div class="title"><span>*</span>超话内容:</div>
      <div class="content_box">
        <van-field
          rows="6"
          v-model="form.topicContent"
          style="background: rgba(0, 0, 0, 0.04); border-radius: 16px"
          type="textarea"
          placeholder="请写下您的超话内容"
          maxlength="300"
          show-word-limit
        />
      </div>
      <div class="title"><span>*</span>封面图片:</div>
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.topicPicture"
        :activityPicture.sync="form.topicPicture"
        :maxCount="1"
        ref="load"
      ></v-upload>
      <div class="message">注：仅可上传一张图片，建议上传正方形图片</div>
      <div class="title"><span>*</span>图片:</div>
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.topicInteriorView"
        :activityPicture.sync="form.topicInteriorView"
        :maxCount="9"
        ref="load"
      ></v-upload>
      <div class="message">注：最多可上传9张图片</div>
    </div>
    <div class="submit" @click="submit()">发布超话</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { addTopicUrl } from "./api.js";
export default {
  name: "releaseTopic",
  data() {
    return {
      form: {
        topicTitle: "",
        informationCopywriting: "",
        topicContent: "",
        topicPicture: "",
        topicInteriorView: "",
      },
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    submit() {
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (this.form.topicTitle == "") {
        this.$toast("超话标题不能为空！");
        return;
      }
      // if (this.form.informationCopywriting == "") {
      //   this.$toast("超话状态文案不能为空！");
      //   return;
      // }
      if (this.form.topicContent == "") {
        this.$toast("超话内容不能为空！");
        return;
      }
      if (!this.form.topicPicture || this.form.topicPicture == "") {
        this.$toast("请上传封面图片!");
        return;
      }
      if (!this.form.topicInteriorView || this.form.topicInteriorView == "") {
        this.$toast("请上传内容图片!");
        return;
      }
      let params = {
        tenantId: this.tenantId,
        communityId: this.communityId,
        topicTitle: this.form.topicTitle,
        topicPicture: this.form.topicPicture,
        topicInteriorView: this.form.topicInteriorView,
        informationCopywriting: this.form.informationCopywriting,
        topicContent: this.form.topicContent,
        topicType: 15,
        createUser: this.userId,
      };
      this.$axios.post(`${addTopicUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast("发布成功~");
          this.timer = setTimeout(() => {
            this.$router.replace({
              name: "topicList",
            });
          }, 200);
        } else {
        }
      });
    },
  },
};
</script>

<style lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 82px;
  box-sizing: border-box;

  .submit {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #9591ff 0%, #605cff 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 76px;
  }

  .top_box {
    width: 100%;
    padding: 0 44px;
    box-sizing: border-box;

    .name_box,
    .status_box {
      width: 100%;
      height: 112px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      line-height: 112px;
      display: flex;

      .box_l {
        width: 240px;
        font-size: 32px;
        color: #333333;

        span {
          color: #ff602a;
        }
      }

      .box_r {
        flex: 1;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.5);

        .message {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
          text-align: right;
        }

        input {
          width: 100%;
          height: 100%;
          text-align: right;
        }

        input::placeholder {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .message {
      margin-top: 12px;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.5);
    }

    .title {
      font-size: 32px;
      color: #333333;
      padding: 40px 0 18px 0;

      span {
        color: #ff602a;
      }
    }
  }
}
</style>
